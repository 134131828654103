<template>
  <div class="faq-content">
    <div class="header">
      <div class="text">
        <h1>{{faq_title}}</h1>
      </div>
    </div>
    <div class="main">
      <div class="faq_content">{{faq_header}}</div>
      <div class="faqitem" v-for="(item,index) in faqLsit" :key="item.title">
        <div class="title">
           <h2> {{index+1}}.{{ item.title }}</h2>
        </div>
        <div class="faq_content" v-html="item.content"></div>
      </div>
      <hr>
      <blockquote class="faq_footer faq_content">
        {{faq_footer}}
      </blockquote>
    </div>
  </div>
</template>

<script>
import { faqData } from "@/api/faq";
export default {
  data() {
    return {
      faq_bg: "",
      faq_title: "",
      faq_header: "",
      faq_footer: "",
      faqLsit: [],
    };
  },
  created() {
    faqData().then((res) => {
      this.faq_bg = res.data.faq_bg;
      this.faq_title = res.data.faq_title;
      this.faq_header = res.data.faq_header;
      this.faq_footer = res.data.faq_footer;
      this.faqLsit = res.data.faq;
    });
  },
  methods: {},
  mounted() {},
  components: {},
  computed: {},
  watch: {},
};
</script>

<style lang="less" scoped>
.header {
  background: url(../../assets/imgs/home/texture-bw.png) center center repeat;
  height: 113px;
  .text {
    max-width: 1140px;
    margin: 0 auto;
    line-height: 113px;
    font-size: 20px;
  }
  h1 {
    font-weight: bolder;
    line-height: 113px;
    margin-top: 0px;
  }
}
.main{
    max-width: 1160px;
    margin: 15px auto;
    margin-top: 40px;
    font-size: 16px;
    padding: 0 10px;
    .title{
      margin-bottom: 10px;
    }
    h2 {
      font-family: "Roboto", Helvetica, Arial, sans-serif;
      color: #333333;
      font-size: 30px;
      font-weight: bolder;
      margin: 20px 0;
    }
    hr {
      margin-top: 20px;
      margin-bottom: 20px;
      border: 0;
      border-top: 1px solid #eeeeee;
    }
    .faq_content {
      font-family: "Roboto", Helvetica, Arial, sans-serif;
      font-size: 14px;
      line-height: 1.42857143;
      color: #555555;
      margin-bottom: 20px;
    }
  .faq_footer {
    padding: 10px 20px;
    margin: 0 0 20px;
    font-size: 14px;
    border-left: 5px solid #38a7bb;
  }
}
</style>
